<template>
  <v-form lazy-validation v-model="valid" ref="form">
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
            <v-subheader>Name <span class="red--text">*</span></v-subheader>
          </v-col>
          <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
            <v-text-field
              v-model="form.name"
              required
              outlined
              dense
              flat
              background-color="white"
              :rules="[v => !!v || 'Name is required']"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
            <v-subheader>Percentage <span class="red--text">*</span></v-subheader>
          </v-col>
          <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
            <v-text-field
              v-model.number="form.percentage"
              type="number"
              outlined
              dense
              flat
              background-color="white"
              :rules="[v => !!v || 'Percentage is required']"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
            <v-subheader>Code <span class="red--text">*</span></v-subheader>
          </v-col>
          <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
            <v-text-field
              v-model="form.code"
              required
              outlined
              dense
              flat
              background-color="white"
              :rules="[v => !!v || 'Code is required']"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0" cols="12">
        <v-btn class="mr-4" @click="submit" color="primary">
          submit
        </v-btn>
        <v-btn @click="clear">
          clear
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  name: "create-setting-tax",
  data: () => ({
    defaultForm: {
      name: "",
      percentage: 0,
      code: "",
    },
    form: {},
    valid: true,
  }),

  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        this.$store.commit("SET_LOADER", true);
        await this.$store
          .dispatch("settingTax/create", this.form)
          .then(() => this.$store.commit("SET_LOADER", false))
          .catch(() => {
            this.$store.dispatch("toast", {
              type: "error",
              message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
            });
            this.$store.commit("SET_LOADER", false);
          });
      } else {
        this.$store.dispatch("toast", {
          type: "error",
          message: "Form Harus Diisi semua",
        });
      }
    },
    clear() {
      this.form = Object.assign({}, this.defaultForm);
    },
  },

  mounted() {
    this.form = Object.assign({}, this.defaultForm);
  },
};
</script>

<style></style>
